@import "../node_modules/bootstrap/scss/bootstrap";

$theme-colors: (
    "info": tomato,
    "danger": teal
);

.navbar .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.btn-link {
  padding: 0;
}

.col {
  position: relative;
  z-index: 999;
  color: white;
}

.stayline {
  display: inline-block;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

footer {
  bottom: 24px;
  z-index: 9999;
  position: absolute;
}
